<template>
  <div class="body">
    <div class="back-btn">
      <div class="back-icon" @click="toBack">
        <img src="../../assets/images/goback.png" />
        返回
      </div>
      <p class="text">成绩信息</p>
    </div>
    <div class="contain" v-loading="loading">
      <div class="left">
        <div class="item">
          <div>
            <p class="text1">
              {{ done.length + noDone.length + error.length }}
            </p>
            <p>文件</p>
          </div>
          <div>
            <p class="text1">{{ done.length + error.length }}</p>
            <p>专题</p>
          </div>
          <div>
            <p class="text1">100</p>
            <p>总分</p>
          </div>
          <div>
            <p class="text1" style="color: red">{{ examScore }}</p>
            <p>得分</p>
          </div>
        </div>
        <div class="explain">
          <div>测试说明</div>
          <div>1、本次试题全部为随机抽取</div>
          <div>2、点击重做则不保留上一次的测试成绩</div>
        </div>
      </div>
      <div class="right">
        <p>答案结果</p>
        <div class="div">
          <p>业务测评类型：{{ user.compName }}</p>
          <p>姓名：{{ user.saveUserName }}</p>
        </div>
        <el-table :data="tableData" border stripe style="width: 100%">
          <el-table-column prop="type" label="类型"> </el-table-column>
          <el-table-column prop="number" label="对应题目"> </el-table-column>
          <el-table-column prop="score" label="成绩">
            <template slot-scope="scope">
              {{ scope.row.score.toFixed(2)}}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="btn">
      <el-button
        @click="restart"
        class="_ImgBtn"
        style="background-color: #92cf68; color: #ffffff"
        ><img src="../../assets/images/learn9.png" />重做</el-button
      >
      <!-- <el-button
        class="_ImgBtn"
        style="background-color: #fa6567; color: #ffffff"
        ><img src="../../assets/images/learn6.png" />上传成绩</el-button
      > -->
    </div>
  </div>
</template>

<script>
import { deleteMethod, get } from "../../api/http";
export default {
  data() {
    return {
      tableData: [],
      examScore: 0,
      done: [],
      noDone: [],
      error: [],
      showDialog: false,
      itemIndex: 0,
      questionList: [],
      user: {},
      loading:false,
    };
  },
  mounted() {
    this.done = JSON.parse(this.$route.query.done);
    this.noDone = JSON.parse(this.$route.query.noDone);
    this.error = JSON.parse(this.$route.query.error);
    this.getData();
  },
  methods: {
    getData() {
      get(
        "/api/ExamPlan/GetByUserId?UserId=" + this.$store.state.userInfo.id
      ).then((resp) => {
        if (resp.code == 200) {
          var list = resp.data.filter((i) => {
            return i.id == this.$route.query.id;
          });
          this.user = list[0];
          this.examScore = list[0].examScore;
        }
      });
      var done = "";
      var error = "";
      var noDone = "";
      this.error.map((i) => {
        error = error == "" ? i.index : error + "、" + i.index;
      });
      this.done.map((i) => {
        done = done == "" ? i : done + "、" + i;
      });
      this.noDone.map((i) => {
        noDone = noDone == "" ? i : noDone + "、" + i;
      });
      var number = this.done.length + this.error.length + this.noDone.length;
      this.tableData = [
        {
          type: "正确",
          number: done,
          score: (this.done.length / number) * 100,
        },
        {
          type: "错误",
          number: error,
          score: 0,
        },
        {
          type: "未做",
          number: noDone,
          score: 0,
        },
      ];
    },
    toBack() {
      this.$router.push("/formal");
    },
    restart() {
      this.$confirm("是否清空已答问题全部重新开始？")
        .then(() => {
          deleteMethod("/api/ExamPlan?Id=" + this.$route.query.id)
            .then((resp) => {
              this.loading = true;
              if (resp.code == 200) {
                if (this.$route.query.companyId) {
                  var url =
                    "./test?index=1" +
                    "&companyId=" +
                    this.$route.query.companyId +
                    "&deptId=" +
                    this.$route.query.deptId;
                  if (this.$route.query.postId) {
                    url = url + "&postId=" + this.$route.query.postId;
                  }
                  this.$router.push(url);
                } else {
                  this.$router.push("./exam?index=2");
                }
              } else {
                this.$message.error(resp.message);
              }
              this.loading = false;
            })
            .catch(() => {
              this.$message.error("失败");
              this.loading = false;
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.body {
  position: absolute;
  top: 0;
  left: 5px;
  height: 85vh;
  background-color: white;
  width: 100%;
}
.back-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  padding: 20px;
  background-image: url("../../assets/images/learn7.png");
  background-size: 100% 100%;
  p {
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: white;
  }
}
.back-icon {
  display: flex;
  align-items: center;
  img {
    width: 15px;
    margin-right: 5px;
  }
}
.contain {
  position: relative;
  margin-top: 80px;
  margin-left: 40px;
  .left {
    position: absolute;
    background-image: url("../../assets/images/learn8.png");
    width: 250px;
    height: 400px;
    top: 50px;
    background-size: 100% 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    .item {
      display: flex;
      flex-wrap: wrap;
      width: 200px;
      height: 180px;
      position: absolute;
      top: 140px;
      > div {
        width: 100px;
        text-align: center;
        font-size: 13px;
        .text1 {
          font-size: 25px;
          color: #605cdf;
        }
      }
    }
    .explain {
      position: absolute;
      bottom: 20px;
      font-size: 12px;
      font-weight: 500;
      color: white;
    }
  }
  .right {
    width: 60%;
    position: absolute;
    right: 150px;
    top: 50px;
    > p {
      font-size: 15px;
    }
    .div {
      padding-left: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    /deep/ .el-table__row td:nth-of-type(2) .cell {
      max-height: 150px !important;
      overflow-y: scroll;
    }
  }
}
.btn {
  position: absolute;
  bottom: 40px;
  right: 80px;
  display: flex;
  align-items: center;
}
</style>